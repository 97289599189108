<template>
  <Loading />

  <n-config-provider :theme="darkTheme" :theme-overrides="themeOverrides">
    <n-loading-bar-provider>
      <n-message-provider :placement="messagePlacement">
        <n-notification-provider>
          <n-modal-provider>
            <n-dialog-provider>
              <ToastInjector />
              <div class="sr-guest-layout-wrapper">
                <slot />
              </div>
            </n-dialog-provider>
          </n-modal-provider>
        </n-notification-provider>
      </n-message-provider>
    </n-loading-bar-provider>
  </n-config-provider>
</template>

<script setup lang="ts">
import { darkTheme } from 'naive-ui';
import { getGlobalThemeOverrides } from '../../base/utils/theme-overrides';

const themeOverrides = getGlobalThemeOverrides();
</script>

<style scoped lang="scss">
.sr-guest-layout-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;

  margin: 16px 12px;
}

@media (min-width: 768px) {
  .sr-guest-layout-wrapper {
    margin: 16px 24px;
  }
}

@media (min-width: 1440px) {
  .sr-guest-layout-wrapper {
    width: 1440px;
    margin: 16px auto;
  }
}
</style>
